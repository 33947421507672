import { Button, Typography, Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as AlertIcon } from 'images/icons/ic-alert-circle-full.svg';

interface OwnProps {
  isSelected?: boolean;
  firstText?: string;
  secondText?: string;
  onClick: () => void;
  color?: 'secondary' | 'error';
  disabled?: boolean;
  isOverlapping?: boolean;
  sx?: SxProps;
}

const textStyles = {
  fontSize: '12px',
  lineHeight: 1,
  maxWidth: '54px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TableButton = ({
  isSelected,
  firstText,
  secondText,
  onClick,
  color = 'secondary',
  disabled,
  isOverlapping,
  sx,
}: OwnProps) => {
  const theme = useTheme();

  const isSelectedAndDisabled = disabled && !isSelected;

  return (
    <Button
      variant={isSelected ? 'contained' : 'text'}
      color={color}
      disabled={disabled}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '40px',
        padding: '5px',
        flex: 1,
        minWidth: 0,
        backgroundColor: isSelected ? 'secondary' : 'zircon',
        border: 0,
        position: 'relative',
        '&:hover': {
          border: 0,
        },
        ...(isSelectedAndDisabled && {
          backgroundColor: 'disabled',
        }),
        ...(isOverlapping &&
          isSelected && {
            backgroundColor: 'accent.red',
            '&:hover': {
              backgroundColor: 'accent.red',
            },
          }),
        ...sx,
      }}
      onClick={onClick}
    >
      {isOverlapping && isSelected && (
        <Box sx={{ position: 'absolute', top: '-6px', right: '-4px', color: 'accent.red' }}>
          <AlertIcon />
        </Box>
      )}
      {firstText && (
        <Typography
          sx={{
            ...textStyles,
            fontWeight: 600,
            color: isSelected ? theme.palette.white : 'navyBlue',
            ...(isSelectedAndDisabled && {
              color: 'disabled2',
            }),
            marginBottom: '4px',
          }}
        >
          {firstText}
        </Typography>
      )}
      {secondText && (
        <Typography
          sx={{
            ...textStyles,
            color: isSelected ? theme.palette.white : 'comet',
            ...(isSelectedAndDisabled && {
              color: 'disabled2',
            }),
          }}
        >
          {secondText}
        </Typography>
      )}
    </Button>
  );
};
