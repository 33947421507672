import { ButtonBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as CloseIcon } from 'images/icons/ic-close.svg';

interface OwnProps {
  onClose: () => void;
}
export const CloseButton = ({ onClose }: OwnProps) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        padding: 0,
        backgroundColor: 'zircon',
        borderRadius: theme.borderRadius.small,
        transition: 'background-color 300ms, stroke 300ms',
        '& svg.ic-stroke path': {
          stroke: `${theme.palette.comet}`,
        },
        '&:hover': {
          backgroundColor: 'pigPink',
          '& svg.ic-stroke path': {
            stroke: `${theme.palette.accent.red}`,
          },
        },
      }}
      onClick={onClose}
    >
      <CloseIcon />
    </ButtonBase>
  );
};
