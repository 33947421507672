import { useState } from 'react';
import { Box, TextField, InputAdornment, SxProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Moment } from 'moment';
import { dateFormatDate } from 'utils/date-time/dates';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar-v3.svg';
import { CalendarControlsButton } from 'app/components/CalendarControls/_components/CalendarControlsButton';
import { useTranslation } from 'react-i18next';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { DateFormatKey } from 'types/dates';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useTheme } from '@mui/material/styles';
import { RotatingArrow } from 'app/components/StyledComponents/RotatingArrow';
import moment from 'moment/moment';

type Props = {
  id?: string;
  label?: string;
  value: number | null;
  onChange: (timestamp: number) => void;
  shouldDisableDate?: (day: any) => boolean;
  format?: keyof DateFormatKey;
  fullWidth?: boolean;
  hideInput?: boolean;
  disabled?: boolean;
  minDate?: Moment;
  inputFormat?: string;
  isCustom?: boolean;
  variant?: 'primary' | 'secondary';
  iconWidth?: number;
  iconHeight?: number;
  sx?: SxProps;
};

const hideInputStyles = {
  position: 'absolute',
  width: 0,
  top: 0,
  left: 0,
  visibility: 'hidden',
};

export const NewMuiDatePicker = ({
  id,
  label,
  value,
  onChange,
  shouldDisableDate,
  format,
  fullWidth,
  hideInput,
  disabled,
  minDate,
  inputFormat,
  isCustom,
  iconWidth = 16,
  iconHeight = 16,
  sx,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState<DateValidationError | string>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => !disabled && setOpen(true);
  const handleClose = () => setOpen(false);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const customStyles = {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 600,
  };

  const customStylesBaseRoot = {
    padding: 0,
  };

  const textFieldStyles = {
    width: '100%',
    input: {
      cursor: 'pointer',
    },
    disabled: {
      color: 'accent.red',
    },
    '& .MuiInputBase-input': {
      caretColor: 'transparent',
      padding: '10px 8px 10px 0',
      ...(isCustom ? customStyles : {}),
    },
    '& .MuiInputBase-root': {
      paddingLeft: '10px',
      ...(isCustom ? customStylesBaseRoot : {}),
    },
    svg: {
      color: 'navyBlue',
    },
    ...(isCustom ? { '& .MuiOutlinedInput-notchedOutline': { border: 0 } } : {}),
  };

  const hide = hideInput ? hideInputStyles : {};

  const setErrorMessage = (reason: DateValidationError) => {
    switch (reason) {
      case 'shouldDisableDate':
        setCurrentError(t('The end date cannot be earlier than the start date'));
        break;
      default:
        setCurrentError(reason);
    }
  };

  const CustomDay = (props: any) => {
    const { day, ...other } = props;
    return (
      <PickersDay
        {...other}
        day={day}
        sx={{
          borderRadius: '5px',
          fontSize: '14px',
          fontWeight: 500,
          color: 'comet',
          '&:hover': { backgroundColor: 'titanWhite', color: 'dodgerBlue' },
          '&.Mui-selected': {
            backgroundColor: 'blueDodger',
            '&:hover': { color: 'white' },
            '&:focus': { backgroundColor: 'blueDodger' },
          },
        }}
      />
    );
  };

  const handleDateChange = (newValue: Moment | null) => {
    if (newValue) {
      const normalizedDate = newValue.clone().startOf('day');
      const newTimestamp = normalizedDate.utc().unix();

      onChange(newTimestamp);
    }
  };

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto', position: 'relative' }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          disabled={disabled}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          label={label}
          value={value ? moment.unix(value) : null}
          onChange={handleDateChange}
          shouldDisableDate={shouldDisableDate}
          inputFormat={inputFormat || dateFormatDate(format)}
          minDate={minDate}
          onError={(reason) => setErrorMessage(reason)}
          renderDay={(day, selectedDays, pickersDayProps) => <CustomDay {...pickersDayProps} />}
          dayOfWeekFormatter={(day) => day?.substring(0, 2)}
          componentsProps={{
            actionBar: { actions: ['today', 'cancel', 'accept'] },
          }}
          renderInput={(params) => (
            <Box>
              <TextField
                {...params}
                id={id}
                disabled={disabled}
                size="small"
                onClick={handleOpen}
                onKeyDown={onKeyDown}
                placeholder={label}
                helperText={currentError}
                sx={{
                  ...textFieldStyles,
                  ...hide,
                  ...sx,
                }}
                InputProps={{
                  startAdornment: !isCustom ? (
                    <InputAdornment position="start">
                      <CalendarIcon width={iconWidth} height={iconHeight} />
                    </InputAdornment>
                  ) : null,
                  endAdornment: isCustom ? (
                    <InputAdornment position="start" sx={{ marginBottom: '10px' }}>
                      <RotatingArrow
                        open={open}
                        sx={{
                          marginRight: 0,
                          color: `${
                            disabled ? theme.palette.disabled2 : theme.palette.navyBlue
                          } !important`,
                        }}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
              {hideInput && (
                <CalendarControlsButton onClick={handleOpen}>
                  <CalendarIcon width={iconWidth} height={iconHeight} />
                </CalendarControlsButton>
              )}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
