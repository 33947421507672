import React, { useEffect, useReducer } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';
import notificationsActions from 'redux/actions/notifications';
import { AppState } from 'types';
import { Notification } from 'types/notifications';
import { CalendarSettings, TimelineReservation } from 'types/calendar';
import { ReservationState } from 'types/reservation';
import calendarActions from 'redux/actions/calendar';
import reservationActions from 'redux/actions/reservation';

interface StateProps {
  notifications: Notification[];
  failedReservationsList: TimelineReservation[];
  fullBookingModalOpen: boolean;
  calendarSettings: CalendarSettings;
}

interface DispatchProps {
  removeSnackbar: (key: SnackbarKey) => void;
  openFullBookingModal: () => void;
  updateReservation: (reservation: ReservationState) => void;
  changeCalendarDate: (val: any) => any;
  removeReservationFromFailedList: (timelineReservationId: number) => void;
}

const Notifier: React.FC<StateProps & DispatchProps> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, dispatchDisplayed] = useReducer(
    (state: SnackbarKey[], action: { type: string; key: SnackbarKey }) => {
      switch (action.type) {
        case 'ADD':
          return [...state, action.key];
        case 'REMOVE':
          return state.filter((n) => n !== action.key);
        default:
          return state;
      }
    },
    [],
  );

  useEffect(() => {
    //NOTIFICATIONS
    props.notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (!key) return;

      if (dismissed) {
        closeSnackbar(key);
      }

      if (displayed.includes(key)) {
        return;
      }

      // Display snackbar using notistack
      enqueueSnackbar(t(message), {
        key,
        ...options,
        variant: options.variant as VariantType,
      });
      // Keep track of snackbars that we've displayed
      dispatchDisplayed({ type: 'ADD', key });
    });

    //FAILED RESERVATIONS
    // props.failedReservationsList.forEach((failedReservation) => {
    //   const key = failedReservation.id;
    //   // Do nothing if snackbar is already displayed
    //   if (displayed.includes(key)) {
    //     return;
    //   }
    //   enqueueSnackbar(`${t('Reservation not saved')}: ${failedReservation.title}`, {
    //     key: key,
    //     variant: 'error',
    //     autoHideDuration: null,
    //     action: () => (
    //       <Fragment>
    //         <Button onClick={() => handleItemSelect(failedReservation)}>{t('Edit')}</Button>
    //         <Button onClick={() => handleCloseSnackbar(failedReservation)}>{t('Close')}</Button>
    //       </Fragment>
    //     ),
    //   });
    //   dispatchDisplayed({ type: 'ADD', key });
    // });
    // eslint-disable-next-line
  }, [props.notifications, props.failedReservationsList, displayed]);

  return null;
};

function mapStateToProps(state: AppState): StateProps {
  return {
    notifications: state.notifications.notifications,
    failedReservationsList: state.calendar.failedReservationsList,
    fullBookingModalOpen: state.calendar.fullBookingModalOpen,
    calendarSettings: state.calendar.calendarSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    removeSnackbar: bindActionCreators(notificationsActions.removeSnackbar, dispatch),
    openFullBookingModal: bindActionCreators(calendarActions.openFullBookingModal, dispatch),
    updateReservation: bindActionCreators(reservationActions.updateReservation, dispatch),
    changeCalendarDate: bindActionCreators(calendarActions.changeCalendarDate, dispatch),
    removeReservationFromFailedList: bindActionCreators(
      calendarActions.removeReservationFromFailedList,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
