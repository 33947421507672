import { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import notesActions from 'redux/actions/notes';
import { connect } from 'react-redux';
import { AppState } from 'types';
import { useTranslation } from 'react-i18next';
import calendarActions from 'redux/actions/calendar';
import NotesDeleteModal from '../NotesDeleteModal';
import { Permissions } from 'types/auth';
import { CalendarSettings, Restaurant } from 'types/calendar';
import { NewNoteParams, Note } from 'types/notes';
import { MuiDatePicker } from 'app/components/MuiDatePicker';
import { useStatus } from 'hooks/useStatus';
import '../Notes.scss';
import { ProgressOverlay } from 'app/components/Overlays/ProgressOverlay';
import { useForm, Controller } from 'react-hook-form';
import { SideModal } from 'app/components/Modals/SideModal';
import { notesButtonColorOptions, notesFormFieldNames } from 'app/components/Notes/_config';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';
import { ButtonsWithColors } from 'app/components/ButtonsWithColors';
import { DatesMoment } from 'enums/dates';
import { TagSelector } from 'app/components/FormElements/TagSelector';
import { StyledTypography } from 'app/components/TextElements/StyledTypography';
import { useAppSelector } from 'redux/hooks';

interface StateProps {
  permissions: Permissions;
  restaurant: Restaurant;
  createNoteLoading: boolean;
  selectedNote: Note | null;
  calendarSettings?: CalendarSettings;
}

interface DispatchProps {
  createNote: (note: NewNoteParams) => any;
  updateNote: (id: string, note: NewNoteParams) => any;
  setSelectedNote: (id: string | null) => void;
  setLocale: () => void;
}

interface DefaultProps {
  onClose: () => void;
  open: boolean;
  locale?: string;
}

type Props = DefaultProps & StateProps & DispatchProps;

const NotesDrawer = (props: Props) => {
  const { isReadOnly } = useStatus();
  const { t } = useTranslation();

  const current_date = useAppSelector((state) => state.app.app.current_date);

  const selectedDate = moment.unix(current_date).startOf('day');

  const defaultValues = {
    [notesFormFieldNames.color]: notesButtonColorOptions[0].value,
    [notesFormFieldNames.start_date]: selectedDate,
    [notesFormFieldNames.end_date]: selectedDate,
    [notesFormFieldNames.days]: [],
    [notesFormFieldNames.name]: '',
    [notesFormFieldNames.text]: '',
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  const { handleSubmit, reset, watch } = methods;
  const dateFormat = DatesMoment[props.restaurant.settings.date_format_key];
  const selectedNoteId = props?.selectedNote?.id;

  const onSubmit = ({ start_date, end_date, days, color, name, text }: any) => {
    const note: NewNoteParams = {
      date: {
        startDate: start_date.format(dateFormat),
        endDate: end_date.format(dateFormat),
      },
      days: days.filter((day: number | string) => !weekdayDisabledCheck(day)),
      color,
      name,
      text,
    };
    if (selectedNoteId) {
      props.updateNote(selectedNoteId, note).then(() => onModalClose());
    } else {
      props.createNote(note).then(() => onModalClose());
    }
  };

  const startDate: any = watch('start_date');
  const endDate: any = watch('end_date');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isValidStartDate = startDate <= endDate;

  const weekdays = Array(7)
    .fill('')
    .map((_, i) => {
      const date = moment().day((i + 1) % 7);
      return { label: date.format('dddd'), value: date.day() };
    });

  const availableWeekdays =
    endDate.diff(startDate, 'days') > -1
      ? Array(endDate.diff(startDate, 'days') + 1)
          .fill('')
          .reduce((acc, _, i) => {
            const dayNumber = moment(startDate).add(i, 'days').day();
            return !acc.includes(dayNumber) ? [...acc, dayNumber] : acc;
          }, [])
      : [];

  const weekdayDisabledCheck = (dayNumber: number | string) =>
    availableWeekdays.length < 2 || !availableWeekdays.includes(dayNumber);

  const handleDeleteNote = () => {
    if (!props.selectedNote) return;
    setDeleteDialogOpen(true);
  };

  const onReset = () => {
    props.setSelectedNote(null);
    reset(defaultValues);
  };

  const onModalClose = () => {
    props.onClose();
    onReset();
  };

  useEffect(() => {
    if (selectedNoteId) {
      const { start_date, end_date, days, color, name, text } = props.selectedNote || {};
      const note: any = {
        start_date: moment(start_date).startOf('day'),
        end_date: moment(end_date).startOf('day'),
        days,
        color,
        name,
        text,
      };
      reset(note);
    } else {
      onReset();
    }
  }, [selectedNoteId, current_date]);

  return (
    <SideModal
      title={t('noteModalTitle')}
      handleSubmit={handleSubmit}
      methods={methods}
      onClose={onModalClose}
      onSubmit={onSubmit}
      open={props.open}
      saveDisabled={!isValidStartDate || isReadOnly}
      buttons={
        selectedNoteId ? (
          <Button disabled={isReadOnly} onClick={handleDeleteNote} variant="outlined" color="error">
            {t('deleteNote')}
          </Button>
        ) : null
      }
    >
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledTypography sx={{ marginBottom: '8px' }}>{t('noteSelectTime')}</StyledTypography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Controller
              name={notesFormFieldNames.start_date}
              control={methods.control}
              render={({ field }) => (
                <MuiDatePicker
                  fullWidth
                  label={t('noteStartDate')}
                  disabled={isReadOnly}
                  format={props.restaurant.settings.date_format_key}
                  value={field.value ? moment(field.value, dateFormat) : null}
                  onChange={(date) => {
                    field.onChange(date ? date : '');
                  }}
                />
              )}
            />
            <Controller
              name={notesFormFieldNames.end_date}
              control={methods.control}
              render={({ field }) => (
                <MuiDatePicker
                  fullWidth
                  label={t('noteEndDate')}
                  disabled={isReadOnly}
                  format={props.restaurant.settings.date_format_key}
                  value={field.value ? moment(field.value, dateFormat) : null}
                  onChange={(date) => {
                    field.onChange(date ? date : '');
                  }}
                  shouldDisableDate={(date) =>
                    date?.isBefore(methods.getValues(notesFormFieldNames.start_date)) || false
                  }
                />
              )}
            />
          </Box>
        </Box>
        <TagSelector
          name={notesFormFieldNames.days}
          label={t('noteRepeat')}
          tags={weekdays}
          disabledCheck={weekdayDisabledCheck}
        />

        <ButtonsWithColors
          name={notesFormFieldNames.color}
          buttonOptions={notesButtonColorOptions}
        />
        <ControlledInput
          label={t('noteName')}
          disabled={isReadOnly}
          name={notesFormFieldNames.name}
        />
        <MuiTextarea
          label={t('noteMessage')}
          disabled={isReadOnly}
          name={notesFormFieldNames.text}
        />
        <ProgressOverlay isLoader open={props.createNoteLoading} />
      </Box>
      {!!props.selectedNote && (
        <NotesDeleteModal
          isOpen={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          note={props.selectedNote}
          onReset={() => onReset()}
        />
      )}
    </SideModal>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    permissions: state.auth.userSettings.permissions,
    restaurant: state.calendar.restaurant,
    createNoteLoading: state.notes.createNoteLoading,
    selectedNote: state.notes.selectedNote,
    calendarSettings: state.calendar.calendarSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    createNote: bindActionCreators(notesActions.createNote, dispatch),
    updateNote: bindActionCreators(notesActions.updateNote, dispatch),
    setSelectedNote: bindActionCreators(notesActions.setSelectedNote, dispatch),
    setLocale: bindActionCreators(calendarActions.setLocale, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDrawer);
