import { Box, Button } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { useTranslation } from 'react-i18next';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { useController, useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { MutableRefObject } from 'react';
import { useStatus } from 'hooks/useStatus';
import { useScreenSize } from 'hooks/useScreenSize';

interface OwnProps {
  guestsRef: MutableRefObject<any>;
}
export const NumbersOfGuests = ({ guestsRef }: OwnProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const theme = useTheme();
  const { isReadOnly } = useStatus();
  const name = bookingFormFieldNames.guests;
  const { isBetween901And1340, isBetween561And620, isBetween521And560, isLessThan520 } =
    useScreenSize();

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: 0,
  });

  const buttonStyles = {
    minWidth: '40px',
    height: '40px',
    padding: '8px',
    flex: 1,
    border: `1px solid ${theme.palette.borderColor}`,
    color: 'comet',
    '&:hover': {
      border: `1px solid ${theme.palette.borderColor}`,
    },
  };

  const handleGuestCountClick = (guestCount: number) => {
    onChange(guestCount);
  };

  const renderGuestCountButtons = () => {
    let numberOfButtons;

    switch (true) {
      case isLessThan520:
        numberOfButtons = 9;
        break;
      case isBetween521And560:
        numberOfButtons = 7;
        break;
      case isBetween561And620:
        numberOfButtons = 8;
        break;
      case isBetween901And1340:
        numberOfButtons = 9;
        break;
      default:
        numberOfButtons = 10;
        break;
    }

    return Array.from({ length: numberOfButtons }, (_, i) => i + 1).map((guestCount) => (
      <Button
        disabled={isReadOnly}
        key={guestCount}
        variant={guestCount === value ? 'contained' : 'outlined'}
        color="secondary"
        onClick={() => handleGuestCountClick(guestCount)}
        sx={{
          ...buttonStyles,
          color: guestCount === value ? 'white' : 'navyBlue',
          backgroundColor: guestCount === value ? 'secondary' : 'zircon',
          border: 0,
          '&:hover': {
            border: 0,
          },
          '&.Mui-disabled': {
            borderColor: 'transparent',
          },
        }}
      >
        {guestCount}
      </Button>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Heading>{t('numberOfGuests')}</Heading>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: '100%',
          flexWrap: isLessThan520 ? 'wrap' : 'nowrap',
          justifyContent: isLessThan520 ? 'space-between' : 'flex-start',
          '& > button': {
            flex: isLessThan520 ? '0 1 calc(20% - 8px)' : 1,
          },
          '& > div': {
            flex: isLessThan520 ? '0 1 calc(20% - 8px)' : 'unset',
          },
        }}
      >
        {renderGuestCountButtons()}
        <ControlledInput
          disabled={isReadOnly}
          fullWidth={false}
          name={name}
          hideError={true}
          type="number"
          typeNumberSx={{
            flexDirection: 'column-reverse',
            gap: '1px',
            svg: {
              width: 13,
              height: 13,
            },
          }}
          sx={{
            width: isLessThan520 ? '100%' : '53px',
            '& .MuiInputBase-root': {
              paddingLeft: 0,
              paddingRight: '8px',
            },
          }}
          inputBaseSx={{ paddingLeft: 0, textAlign: 'center' }}
          inputRef={guestsRef}
        />
      </Box>
      <CustomErrorMessage errors={errors} name={name} />
    </Box>
  );
};
