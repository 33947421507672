import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

interface ISidebarNavLink {
  isActive?: boolean;
  disabled?: boolean;
}

const getStyles = (theme: any) => ({
  backgroundColor: theme.palette.brandYellow,
  '& svg path': {
    fill: theme.palette.solidWhite,
  },
  '& .settings-navigation-link': {
    color: theme.palette.solidWhite,
  },
});

export const SidebarNavLink = styled(NavLink, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<ISidebarNavLink>(({ theme, isActive, disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '5px',
  padding: '8px',
  background: theme.palette.jacksonsPurple,
  '& .settings-navigation-link': {
    color: theme.palette.solidWhite,
    fontSize: '10px',
    fontWeight: 600,
  },
  ...(isActive && getStyles(theme)),
  '&:hover': getStyles(theme),
  ...(disabled && {
    cursor: 'not-allowed',
    '& svg path': {
      fill: theme.palette.disabled2,
    },
    '& .settings-navigation-link': {
      color: theme.palette.disabled2,
    },
    '&:hover': {
      background: theme.palette.jacksonsPurple,
    },
  }),
}));
