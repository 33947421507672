import { Box } from '@mui/material';
import { BookingModalFooter } from 'app/components/NewBookingModal/_components/BookingModalFooter';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { BookingModalViews } from 'app/components/NewBookingModal/_config';
import { Statuses } from 'types/reservation';

interface OwnProps {
  children: ReactNode;
  handleModalView: (layout: BookingModalViews) => void;
  currentView: BookingModalViews;
  selectedStatus: Statuses;
}

export const BookingBody = ({
  children,
  handleModalView,
  currentView,
  selectedStatus,
}: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.customSpacing.bookingModal,
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
      <BookingModalFooter
        handleModalView={handleModalView}
        currentView={currentView}
        selectedStatus={selectedStatus}
      />
    </Box>
  );
};
