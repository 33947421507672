import { Statuses } from 'types/reservation';
import { Box, SxProps } from '@mui/material';
import moment from 'moment-timezone';
import { IReservation } from 'types/app/reservations';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  reservation: IReservation;
  showInitialTimeBadge: boolean;
  sx?: SxProps;
}

export const OriginalTime = ({ reservation, showInitialTimeBadge, sx }: OwnProps) => {
  const theme = useTheme();

  return (
    <>
      {reservation &&
        reservation.status !== Statuses.WalkIn &&
        showInitialTimeBadge &&
        !!reservation.original_start_time && (
          <Box
            sx={{
              fontSize: 10,
              color: `${theme.palette.accent.red}`,
              border: `1px solid ${theme.palette.accent.red}`,
              fontWeight: 500,
              lineHeight: 1.1,
              textAlign: 'center',
              borderRadius: '10px',
              padding: '0px 4px',
              width: 'fit-content',
              height: 'fit-content',
              ...sx,
            }}
          >
            {moment.unix(reservation.original_start_time).format('HH:mm')}
          </Box>
        )}
    </>
  );
};
