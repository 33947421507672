import { ChangeEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { SideModal } from 'app/components/Modals/SideModal';
import { useStatus } from 'hooks/useStatus';

enum ChargeModeTypes {
  PerPerson = 'person',
  PerReservation = 'reservation',
}

interface OwnProps {
  guests: number;
  chargeAmount: string;
  minAmount: number;
  onPaymentModalClose: () => void;
}

export const PaymentModal = (props: OwnProps) => {
  const booking = useAppSelector((state) => state.app.booking);
  const lateCancellation = booking?.special_offer?.late_cancellation;
  const modalIsOpen = useAppSelector(
    (state) => state.calendar.lateCancellationNoShowPayment.paymentModalIsOpen,
  );

  const { t } = useTranslation();
  const chargeMode = lateCancellation?.charge_mode;
  const maxDescriptionLength = 255;
  const currency = lateCancellation?.currency;
  const { isReadOnly } = useStatus();

  const handleChargeAmount = (
    field: ControllerRenderProps<FieldValues, string>,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    let total = e.target.value;

    if (isNaN(Number(total))) {
      total = total.replace(/[^0-9\.]/g, '');
      if (total.split('.')?.length > 2) {
        total = total.replace(/\.+$/, '');
      }
    }

    field.onChange(total);
  };

  const handleOnBlur = (field: ControllerRenderProps<FieldValues, string>) => {
    const numValue = parseFloat(field.value);
    if (!isNaN(numValue)) {
      field.onChange(numValue.toFixed(2));
    }

    field.onBlur();
  };

  return (
    <SideModal
      open={modalIsOpen}
      title={t('paymentModalTitle')}
      onClose={props.onPaymentModalClose}
      form="booking-form"
      confirmButtonText={t('chargeButton')}
      additionalElements={
        <Button
          sx={{ height: '40px', lineHeight: 1 }}
          color="secondary"
          variant="outlined"
          onClick={props.onPaymentModalClose}
          disabled={isReadOnly}
        >
          {t('cancel')}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <ControlledInput
          name={bookingFormFieldNames.late_cancellation.amount}
          label={`
          ${t('paymentModalAmountToPay', { currency })} / 
          ${
            chargeMode === ChargeModeTypes.PerReservation
              ? t('paymentModalChargePerReservation')
              : t('paymentModalChargePerPerson')
          }
          `}
          customOnChange={handleChargeAmount}
          onBlur={handleOnBlur}
          disabled={isReadOnly}
        />
        <ControlledInput
          name={bookingFormFieldNames.late_cancellation.description}
          label={t('paymentModalDescription')}
          maxLength={maxDescriptionLength}
          disabled={isReadOnly}
        />
        {parseFloat(props.chargeAmount) >= props.minAmount &&
          chargeMode === ChargeModeTypes.PerPerson && (
            <Typography>
              <Trans
                i18nKey="totalChargeCalculation"
                values={{
                  amount: parseFloat(props.chargeAmount),
                  currency: currency,
                  guests: props.guests,
                  total: parseFloat(props.chargeAmount) * props.guests,
                }}
                components={{ strong: <strong /> }}
              />
            </Typography>
          )}
      </Box>
    </SideModal>
  );
};
