import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  children: ReactNode;
}

export const BookingBodyWrapper = ({ children }: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.customSpacing.bookingModal,
        width: '100%',
        height: '100%',
        flexDirection: { xs: 'column', md: 'row' },
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
};
