export enum NotesColor {
  Danger = 'danger',
  Info = 'info',
  Primary = 'primary',
  Success = 'success',
  Secondary = 'secondary',
}

export const notesFormFieldNames = {
  color: 'color',
  start_date: 'start_date',
  end_date: 'end_date',
  days: 'days',
  name: 'name',
  text: 'text',
};

export const notesButtonColorOptions = [
  { text: 'blue', color: 'accent.blue', value: NotesColor.Primary },
  { text: 'green', color: 'accent.green', value: NotesColor.Success },
  { text: 'yellow', color: 'accent.yellow', value: NotesColor.Info },
  { text: 'purple', color: 'accent.violet', value: NotesColor.Secondary },
  { text: 'red', color: 'accent.red', value: NotesColor.Danger },
];
