import { Box, Typography, ButtonBase, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from 'app/components/CountrySelect';
import { useTheme } from '@mui/material/styles';
import { MuiCheckbox } from 'app/components/FormElements/MuiCheckbox';
import { FlagCode } from 'utils/locale/handleCountryFlag';
import { bookingFormFieldNames, BookingModalViews } from 'app/components/NewBookingModal/_config';
import { SubmitButton } from 'app/pages/RestaurantSettings/_components/SubmitButton';
import { useAppSelector } from 'redux/hooks';
import { selectProfile } from 'redux/selectors/profile';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactComponent as DotsIcon } from 'images/icons/ic-dots.svg';
import { useState } from 'react';
import { useStatus } from 'hooks/useStatus';
import { NotificationStatus } from 'types/app/notificationSettings';
import { Statuses } from 'types/reservation';
import { DeleteReservationButton } from 'app/components/NewBookingModal/_components/DeleteReservationButton';

interface OwnProps {
  handleModalView: (layout: BookingModalViews) => void;
  currentView: BookingModalViews;
  selectedStatus: Statuses;
}

export const BookingModalFooter = ({ handleModalView, currentView, selectedStatus }: OwnProps) => {
  const [isExtended, setIsExtended] = useState(false);
  const { waiters } = useAppSelector(selectProfile);
  const { widget_available_languages } = useAppSelector(
    (state) => state.app.settings.time_and_language,
  );
  const { sms } = useAppSelector((state) => state.app.settings.notification_settings);
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    is_create_reservation_loading,
    is_update_reservation_loading,
    is_delete_reservation_loading,
  } = useAppSelector((state) => state.app.loading);

  const { isMaxXlScreen, isMobile } = useScreenSize();
  const { isReadOnly } = useStatus();

  const toggleExtendedFooter = () => {
    setIsExtended((prevState) => !prevState);
  };

  const smsNotificationCheckbox = sms.status === NotificationStatus.ACTIVE && (
    <MuiCheckbox
      name={bookingFormFieldNames.notifications.sms}
      label={t('reservationSMSLabel')}
      disabled={isReadOnly}
    />
  );

  const isTablesView = currentView === BookingModalViews.Tables;

  return (
    <Box
      sx={{
        flexWrap: 'wrap',
        ...(isMaxXlScreen && {
          position: 'sticky',
          '&::before, &::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            height: '12px',
            backgroundColor: 'linkWater2',
          },
          '&::before': {
            top: '-12px',
          },
          '&::after': {
            bottom: '-12px',
          },
          bottom: '12px',
        }),
        zIndex: 3,
      }}
    >
      <Box sx={{ backgroundColor: 'linkWater2' }}>
        {isExtended && (
          <Box
            sx={{
              backgroundColor: 'brandWhite',
              borderRadius: theme.borderRadius.small,
              marginBottom: theme.customSpacing.bookingModal,
              display: { xd: 'flex', sm: 'none' },
            }}
          >
            <Box sx={{ padding: '16px' }}>
              <CountrySelect
                languages={widget_available_languages as FlagCode[]}
                name={bookingFormFieldNames.notifications.language}
                isGridLayout={true}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                flexWrap: 'wrap',
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderColor: 'borderColor',
                padding: '16px',
              }}
            >
              {smsNotificationCheckbox}
              <MuiCheckbox
                name={bookingFormFieldNames.notifications.email}
                label={t('reservationEmailLabel')}
                disabled={isReadOnly}
              />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            padding: { xs: '12px 16px', xl: `12px ${theme.paddings.bookingModal}` },
            display: 'flex',
            alignItems: 'center',
            justifyContent: isTablesView ? 'flex-end' : 'space-between',
            gap: '8px',
            borderRadius: theme.borderRadius.small,
            backgroundColor: 'brandWhite',
          }}
        >
          {!isTablesView && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '16px' }}>
              <ButtonBase
                sx={{
                  borderRadius: theme.borderRadius.small,
                  color: 'navyBlue',
                  display: { xs: 'flex', sm: 'none' },
                }}
                onClick={toggleExtendedFooter}
              >
                <DotsIcon />
              </ButtonBase>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  gap: '24px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CountrySelect
                  languages={widget_available_languages as FlagCode[]}
                  name={bookingFormFieldNames.notifications.language}
                />
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  {smsNotificationCheckbox}
                  <MuiCheckbox
                    name={bookingFormFieldNames.notifications.email}
                    label={t('reservationEmailLabel')}
                    disabled={isReadOnly}
                  />
                </Box>
              </Box>
              {isMobile && <DeleteReservationButton selectedStatus={selectedStatus} />}
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', alignItems: 'center' }}>
            {isTablesView ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '8px',
                }}
              >
                <Button
                  color="secondary"
                  onClick={() => handleModalView(BookingModalViews.Default)}
                  variant="outlined"
                  sx={{
                    minHeight: '40px',
                  }}
                >
                  {t('bookingModalBackButton')}
                </Button>
                <Button
                  color="primary"
                  name={t('bookingModalConfirmTablesButton')}
                  onClick={() => handleModalView(BookingModalViews.Default)}
                  variant="contained"
                  sx={{
                    minHeight: '40px',
                  }}
                >
                  {t('bookingModalConfirmTablesButton')}
                </Button>
              </Box>
            ) : (
              <>
                {!!waiters.length &&
                  (waiters.length === 1 ? (
                    <Typography
                      sx={{
                        color: 'navyBlue',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                      }}
                    >
                      {waiters[0].label}
                    </Typography>
                  ) : (
                    <MuiSelect
                      name={bookingFormFieldNames.waiter_id}
                      options={waiters}
                      sx={{ maxWidth: '200px' }}
                    />
                  ))}
                <SubmitButton
                  sx={{ width: 'fit-content', minHeight: '40px' }}
                  color="primary"
                  isDisabled={
                    is_create_reservation_loading ||
                    is_update_reservation_loading ||
                    is_delete_reservation_loading ||
                    isReadOnly
                  }
                >
                  {t('saveReservation')}
                </SubmitButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
