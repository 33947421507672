import { Box } from '@mui/material';
import { ReactNode, FormEvent, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { DialogVariant, StyledDialog } from 'app/components/Modals/StyledDialog';
import { SubmitButton } from 'app/pages/RestaurantSettings/_components/SubmitButton';
import { CloseButton } from 'app/components/NewBookingModal/_components/CloseButton';
import { ProgressOverlay } from 'app/components/Overlays/ProgressOverlay';
import { useStatus } from 'hooks/useStatus';
import { MainHeading } from 'app/components/TextElements/MainHeading';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  buttons?: ReactNode;
  saveDisabled?: boolean;
  methods?: any;
  handleSubmit?: any;
  onSubmit?: any;
  onClick?: any;
  variant?: DialogVariant;
  additionalElements?: ReactNode;
  paperSx?: CSSProperties;
  isLoading?: boolean;
  form?: string;
  confirmButtonText?: string;
  withSaveButton?: boolean;
}

export const SideModal = ({
  open,
  onClose,
  children,
  title,
  buttons,
  methods,
  handleSubmit,
  onSubmit,
  saveDisabled,
  onClick,
  variant,
  additionalElements,
  paperSx,
  isLoading = false,
  form,
  confirmButtonText,
  withSaveButton = true,
}: OwnProps) => {
  const { t } = useTranslation();
  const { isReadOnly } = useStatus();

  const handleModalSubmit = (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmit(onSubmit)(event);
  };

  const formContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          padding: '12px 24px',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderColor: 'borderColor',
          backgroundColor: 'brandWhite',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <MainHeading>{title}</MainHeading>
        <CloseButton onClose={onClose} />
      </Box>
      <ProgressOverlay isLoader={true} open={isLoading} />
      <Box sx={{ padding: '24px' }}>{children}</Box>
    </Box>
  );

  const footerContent = (
    <Box
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderColor: 'borderColor',
        padding: '12px 24px',
        display: 'flex',
        gap: '16px',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'brandWhite',
        justifyContent: 'flex-end',
      }}
    >
      {additionalElements}
      {buttons}
      {withSaveButton && (
        <SubmitButton
          sx={{ height: '40px', marginTop: 0 }}
          disabled={saveDisabled || isReadOnly}
          onClick={onClick}
          form={form}
        >
          {confirmButtonText || t('save')}
        </SubmitButton>
      )}
    </Box>
  );

  return (
    <StyledDialog open={open} onClose={onClose} variant={variant} paperSx={paperSx}>
      {handleSubmit ? (
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleModalSubmit}
            sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
          >
            {formContent}
            {footerContent}
          </Box>
        </FormProvider>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {formContent}
          {footerContent}
        </Box>
      )}
    </StyledDialog>
  );
};
