import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { ReactComponent as ClockIcon } from 'images/icons/ic-clock.svg';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentOpeningHours } from 'redux/selectors/openingHours';
import { useEffect, useMemo, useState } from 'react';
import { range } from 'lodash';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { useFormContext } from 'react-hook-form';
import { useStatus } from 'hooks/useStatus';

export const DateAndTime = () => {
  const { t } = useTranslation();
  const { time } = useAppSelector(selectCurrentOpeningHours);
  const interval = useAppSelector((state) => state.app.settings.reservation.interval);
  const { setValue, watch, getValues, setError, clearErrors } = useFormContext();
  const [prevStartTime, setPrevStartTime] = useState(time.start);
  const [prevEndTime, setPrevEndTime] = useState(time.end);
  const { isReadOnly } = useStatus();

  const timeOptions = useMemo(() => {
    return range(time.start, time.end + interval, interval).map((time) => {
      return {
        label: createMomentUtc(time).format('HH:mm'),
        value: time,
      };
    });
  }, [time.start, time.end]);

  const startTime = watch(bookingFormFieldNames.start_time);
  const endTime = watch(bookingFormFieldNames.end_time);

  const setFirstAvailableStartTime = () => {
    setValue(bookingFormFieldNames.start_time, time.start);
  };

  const setEndTime = (callback?: (value: number) => void) => {
    //TODO settings
    const startTimePlusTwoHours = time.start + 7200;
    setValue(bookingFormFieldNames.end_time, startTimePlusTwoHours);
    if (callback) {
      callback(startTimePlusTwoHours);
    }
  };

  useEffect(() => {
    if (!startTime) {
      setFirstAvailableStartTime();
    }
    if (!endTime) {
      setEndTime();
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (prevStartTime !== time.start) {
      setFirstAvailableStartTime();
      setPrevStartTime(time.start);
    }
    if (prevEndTime !== time.end) {
      setEndTime(setPrevEndTime);
    }
  }, [time.start, prevStartTime, time.end, prevEndTime]);

  const currentStartTimeValue = getValues(bookingFormFieldNames.start_time);
  const currentEndTimeValue = getValues(bookingFormFieldNames.end_time);

  const validateTime = async (fieldName: string, newValue: number) => {
    const startTime =
      fieldName === bookingFormFieldNames.start_time ? newValue : currentStartTimeValue;
    const endTime = fieldName === bookingFormFieldNames.end_time ? newValue : currentEndTimeValue;

    if (startTime && endTime && startTime >= endTime) {
      setError(bookingFormFieldNames.start_time, {
        type: 'custom',
        message: t('bookingModalStartTimeBeforeEndTime'),
      });
      setError(bookingFormFieldNames.end_time, {
        type: 'custom',
        message: t('bookingModalStartTimeBeforeEndTime'),
      });
    } else {
      clearErrors([bookingFormFieldNames.start_time, bookingFormFieldNames.end_time]);
    }
  };

  return (
    <Box>
      <Heading>{t('dateAndTime')}</Heading>
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: { xs: 'column', md: 'row' } }}>
        <MuiSelect
          disabled={isReadOnly}
          name={bookingFormFieldNames.start_time}
          options={timeOptions}
          icon={<ClockIcon />}
          translateLabels={false}
          placeholder={
            createMomentUtc(currentStartTimeValue).format('HH:mm') || t('startTimePlaceholder')
          }
          customOnChange={(e) =>
            validateTime(bookingFormFieldNames.start_time, Number(e.target.value))
          }
        />
        {/*<MuiSelect*/}
        {/*  name={bookingFormFieldNames.duration}*/}
        {/*  options={[*/}
        {/*    {*/}
        {/*      label: '2h',*/}
        {/*      value: 120,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  icon={<ClockIcon />}*/}
        {/*  translateLabels={false}*/}
        {/*  placeholder={t('durationPlaceholder')}*/}
        {/*/>*/}
        <MuiSelect
          disabled={isReadOnly}
          name={bookingFormFieldNames.end_time}
          options={timeOptions}
          icon={<ClockIcon />}
          translateLabels={false}
          placeholder={
            createMomentUtc(currentEndTimeValue).format('HH:mm') || t('endTimePlaceholder')
          }
          customOnChange={(e) =>
            validateTime(bookingFormFieldNames.end_time, Number(e.target.value))
          }
        />
      </Box>
    </Box>
  );
};
