import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useRef, useState, RefObject } from 'react';
import { Statuses } from 'types/reservation';
import { ReservationSection } from 'app/components/NewBookingModal/_components/ReservationSection';
import { GuestSection } from 'app/components/NewBookingModal/_components/GuestSection';
import { AvailableTablesSection } from 'app/components/NewBookingModal/_components/AvailableTablesSection';
import { BookingDialog } from 'app/components/NewBookingModal/_components/BookingDialog';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { BookingFormData } from 'types/app/booking';
import { useSnackbar } from 'notistack';
import { MessageVariants } from 'enums/notifications';
import { useTranslation } from 'react-i18next';
import reservationsActions from 'redux/actions/app/reservations';
import bookingActions from 'redux/actions/app/booking';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { bookingInitialState } from 'redux/constants/app/bookingInitialState';
import { merge } from 'lodash';
import {
  bookingFormFieldNames,
  BookingModalLayouts,
  BookingModalViews,
  getReservationValidationSchema,
} from 'app/components/NewBookingModal/_config';
import { checkIfReservationIsNew } from 'utils/reservation/checkIfReservationIsNew';
import { BookingContentWrapper } from 'app/components/NewBookingModal/_components/BookingContentWrapper';
import { useTheme } from '@mui/material/styles';
import { PaymentConfirmationModal } from 'app/components/Modals/PaymentConfirmationModal';
import { PaymentModal } from 'app/components/Modals/PaymentModal';
import calendarActions from 'redux/actions/calendar';
import { getAmountDivider } from 'utils/number/getAmountDivider';
import { NotificationStatus } from 'types/app/notificationSettings';

export const NewBookingModal = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [currentView, setCurrentView] = useState(BookingModalViews.Default);
  const [tablesLayout, setTablesLayout] = useState(BookingModalLayouts.Tables);
  const is_booking_modal_open = useAppSelector((state) => state.app.app.is_booking_modal_open);
  const { phone_required } = useAppSelector((state) => state.app.settings.reservation);
  const { widget_default_language } = useAppSelector(
    (state) => state.app.settings.time_and_language,
  );
  const booking = useAppSelector((state) => state.app.booking);
  const { sms } = useAppSelector((state) => state.app.settings.notification_settings);
  const lateCancellation = booking?.special_offer?.late_cancellation;

  const isNewReservation = checkIfReservationIsNew(booking?.id);
  const minAmount = 0.5;

  const isLateCancellation =
    booking?.special_offer?.late_cancellation &&
    !Object.values(booking?.special_offer?.late_cancellation).every((value) => value === null);

  const {
    is_create_reservation_loading,
    is_update_reservation_loading,
    is_delete_reservation_loading,
  } = useAppSelector((state) => state.app.loading);

  const guestsRef = useRef(null);
  const guestNameRef = useRef(null);
  const guestPhoneRef = useRef(null);
  const tablesRef = useRef(null);

  const refs = {
    guests: guestsRef,
    client: {
      name: guestNameRef,
      phone: guestPhoneRef,
    },
    tables: tablesRef,
  };

  const schema = yup.object(
    getReservationValidationSchema(
      t,
      minAmount,
      getAmountDivider(lateCancellation?.maximum_amount),
      255,
      lateCancellation?.currency,
    ),
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: booking,
    resolver: yupResolver(schema),
    context: { phone_required, isLateCancellation },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const selectedStatus: Statuses = watch(bookingFormFieldNames.status);
  const guests = watch(bookingFormFieldNames.guests);
  const chargeAmount = watch(bookingFormFieldNames.late_cancellation.amount);

  const onClose = () => dispatch(bookingActions.closeAndResetBooking(reset));

  const onPaymentModalClose = () => {
    setValue('late_cancellation', null);
    clearErrors(['late_cancellation.amount', 'late_cancellation.description']);

    dispatch(calendarActions.closeLateCancellationNoShowPaymentModal());
  };

  const isPaymentModalAvailable =
    isLateCancellation &&
    (selectedStatus === Statuses.NoShow || selectedStatus === Statuses.Cancelled);

  const onSubmit = (data: BookingFormData) => {
    (async () => {
      const filteredComments =
        data?.comments?.filter((comment) => comment.text.trim() !== '' && comment?.user_id !== 0) ||
        [];
      const newData = {
        ...data,
        payments: null,
        stripe_payment_data: null,
        reservation_date: null,
        comments: filteredComments,
      };

      if (isNewReservation) {
        try {
          dispatch(bookingActions.closeAndResetBooking(reset));
          await dispatch(bookingActions.createReservation(newData));
          await dispatch(reservationsActions.getFullReservationsForDay());
        } catch (error) {
          enqueueSnackbar(t('createReservationError'), { variant: MessageVariants.Error });
        }
      } else {
        try {
          if (isPaymentModalAvailable) {
            onPaymentModalClose();
          }
          dispatch(bookingActions.closeAndResetBooking(reset));
          await dispatch(
            bookingActions.updateReservation({
              id: newData.id,
              ...newData,
            }),
          );
          if (isPaymentModalAvailable) {
            enqueueSnackbar(t('feeChargedSuccessfully'), { variant: MessageVariants.Success });
          }
          await dispatch(reservationsActions.getFullReservationsForDay());
        } catch (error) {
          enqueueSnackbar(t('updateReservationError'), { variant: MessageVariants.Error });
        }
      }
    })();
  };

  const setBookingStatus = async (status: Statuses) => {
    setValue(bookingFormFieldNames.status, status);
  };

  const scrollToElement = (elementRef: RefObject<HTMLElement>) => {
    elementRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToError = (errors: any, refs: any) => {
    const errorsArray = [
      errors?.guests,
      errors?.tables,
      errors?.client?.name,
      errors?.client?.phone,
    ].filter((error) => error !== undefined);

    if (errorsArray.length > 0) {
      const firstError = errorsArray[0];

      switch (firstError) {
        case errors.guests:
          scrollToElement(refs.guests);
          break;
        case errors.tables:
          scrollToElement(refs.tables);
          break;
        case errors.client?.name:
          scrollToElement(refs.client?.name);
          break;
        case errors.client?.phone:
          scrollToElement(refs.client?.phone);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (is_booking_modal_open) {
      (async () => {
        reset(
          merge(
            {},
            bookingInitialState,
            booking,
            {
              notifications: {
                language: widget_default_language,
              },
            },
            isNewReservation
              ? {
                  notifications: {
                    sms: sms.status === NotificationStatus.ACTIVE,
                    email: true,
                  },
                }
              : {
                  notifications: {
                    sms: false,
                    email: false,
                  },
                },
          ),
        );
      })();
    } else {
      setCurrentView(BookingModalViews.Default);
      setTablesLayout(BookingModalLayouts.Tables);
    }
  }, [is_booking_modal_open, booking, isNewReservation]);

  const handleModalView = (view: BookingModalViews) => {
    setCurrentView(view);
  };

  const handleTablesLayout = (layout: BookingModalLayouts) => {
    setTablesLayout(layout);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToError(errors, refs);
    }
  }, [errors]);

  const renderContent = () => {
    switch (currentView) {
      case BookingModalViews.Default:
        return (
          <>
            <ReservationSection
              selectedStatus={selectedStatus}
              handleModalView={handleModalView}
              guestsRef={guestsRef}
              tablesRef={tablesRef}
            />
            <GuestSection
              selectedStatus={selectedStatus}
              guestNameRef={guestNameRef}
              guestPhoneRef={guestPhoneRef}
            />
          </>
        );
      case BookingModalViews.Tables:
        return (
          <AvailableTablesSection
            tablesLayout={tablesLayout}
            handleModalView={handleModalView}
            handleTablesLayout={handleTablesLayout}
          />
        );
      default:
        return null;
    }
  };

  return (
    <BookingDialog
      onClose={onClose}
      isOpen={is_booking_modal_open}
      isLoading={
        is_create_reservation_loading ||
        is_update_reservation_loading ||
        is_delete_reservation_loading
      }
    >
      <FormProvider {...methods}>
        <Box
          id="booking-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            padding: theme.customSpacing.bookingModal,
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
        >
          <BookingContentWrapper
            setBookingStatus={setBookingStatus}
            selectedStatus={selectedStatus}
            isNewReservation={isNewReservation}
            onClose={onClose}
            handleModalView={handleModalView}
            currentView={currentView}
          >
            {renderContent()}
          </BookingContentWrapper>
          <PaymentConfirmationModal
            selectedStatus={selectedStatus}
            lateCancellationTime={lateCancellation?.time}
          />
          <PaymentModal
            guests={guests}
            chargeAmount={chargeAmount}
            minAmount={minAmount}
            onPaymentModalClose={onPaymentModalClose}
          />
        </Box>
      </FormProvider>
    </BookingDialog>
  );
};
