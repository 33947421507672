import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { SidebarNavigation } from 'app/components/Navigation/_components/SidebarNavigation';
import { TabMenu } from 'app/components/TabMenu';
import { appSpacing, mobileTabMenuHeight } from 'styles/constants/spacing';

interface OwnProps {
  children: ReactNode;
}

export const NavigationWrapper = ({ children }: OwnProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        paddingBottom: { xs: `${mobileTabMenuHeight}px`, md: 0 },
      }}
    >
      <SidebarNavigation />
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            gap: `${appSpacing}px`,
            padding: `${appSpacing}px`,
            flex: 1,
            position: 'relative',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      <TabMenu />
    </Box>
  );
};
