import { ReactComponent as ConfiirmedIcon } from 'images/BookingStatuses/ic-confirmed-status.svg';
import { ReactComponent as WalkinIcon } from 'images/BookingStatuses/ic-walkin-status.svg';
import { ReactComponent as PendingIcon } from 'images/BookingStatuses/ic-pending-status.svg';
import { ReactComponent as NoShowIcon } from 'images/BookingStatuses/ic-no-show-status.svg';
import { ReactComponent as CancelIcon } from 'images/BookingStatuses/ic-cancel-status.svg';
import { ReactComponent as WaitingListIcon } from 'images/BookingStatuses/ic-waiting-list-status.svg';
import { ReactComponent as WebIcon } from 'images/icons/ic-web.svg';
import { ReactComponent as GoogleIcon } from 'images/icons/Socials/ic-google.svg';
import { ReactComponent as FacebookIcon } from 'images/icons/Socials/ic-facebook.svg';
import { ReactComponent as InstagramIcon } from 'images/icons/Socials/ic-instagram.svg';
import { ReactComponent as CustomIcon } from 'images/icons/ic-columns.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/Badges/ic-phone-outlined.svg';
import { Origin, Statuses } from 'types/reservation';

export enum ChannelStatistics {
  Google = 'google',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Custom = 'custom',
}

export const getStatusIcon = (status: Statuses) => {
  switch (status) {
    case Statuses.Confirmed:
      return <ConfiirmedIcon width={16} height={16} />;
    case Statuses.WalkIn:
      return <WalkinIcon width={16} height={16} />;
    case Statuses.Pending:
      return <PendingIcon width={16} height={16} />;
    case Statuses.NoShow:
      return <NoShowIcon width={16} height={16} />;
    case Statuses.Cancelled:
      return <CancelIcon width={16} height={16} />;
    case Statuses.WaitingList:
      return <WaitingListIcon width={16} height={16} />;
    default:
      return null;
  }
};

export const geChannelIcon = (channel: string) => {
  switch (channel) {
    case Origin.Online:
      return <WebIcon />;
    case Origin.Phone:
      return <PhoneIcon />;
    case ChannelStatistics.Google:
      return <GoogleIcon />;
    case ChannelStatistics.Facebook:
      return <FacebookIcon />;
    case ChannelStatistics.Instagram:
      return <InstagramIcon />;
    case ChannelStatistics.Custom:
      return <CustomIcon />;
    default:
      return null;
  }
};
