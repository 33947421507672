import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

interface OwnProps {
  children: ReactNode | ReactNode[];
  sx?: SxProps;
}

export const CustomCard = ({ children, sx }: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: '12px',
        borderRadius: theme.borderRadius.default,
        backgroundColor: 'zirconDark',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
