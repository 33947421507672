import { Box, Typography } from '@mui/material';
import { ReactComponent as StarIcon } from 'images/icons/ic-star.svg';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  name: string;
  status: string;
}

type StatusColors = {
  [key: string]: string;
};

const statusColors: StatusColors = {
  paid: 'accent.green',
  card_saved: 'accent.yellow',
  rating: 'accent.yellow',
  black_listed: 'darkNavyBlue',
  big_spender: 'accent.green',
  vip: 'accent.red',
};

export const StyledLabel = ({ name, status }: OwnProps) => {
  const backgroundColor = statusColors[status] || 'lightgrey';
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor,
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px 8px',
        '& svg.ic-fill path': {
          fill: `${theme.palette.white}`,
        },
      }}
    >
      {status === 'rating' && <StarIcon />}
      <Typography sx={{ fontSize: '12px', fontWeight: 600, color: 'white' }}>{name}</Typography>
    </Box>
  );
};
