import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { timestamp } from 'utils/date-time/timestamp';

interface OwnProps {
  changed: number;
}

export const CommentDate = ({ changed }: OwnProps) => {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        color: 'comet',
      }}
    >
      {moment(timestamp(changed).toMilliseconds).format('YYYY-MM-DD · HH:mm')}
    </Typography>
  );
};
