import { useAppDispatch, useAppSelector } from 'redux/hooks';
import bookingActions from 'redux/actions/app/booking';
import reservationsActions from 'redux/actions/app/reservations';
import { MessageVariants } from 'enums/notifications';
import { useFormContext } from 'react-hook-form';
import { BookingFormData } from 'types/app/booking';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useStatus } from 'hooks/useStatus';
import { Statuses } from 'types/reservation';

interface OwnProps {
  selectedStatus: Statuses;
}

export const DeleteReservationButton = ({ selectedStatus }: OwnProps) => {
  const { isReadOnly } = useStatus();
  const [showButtons, setShowButtons] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.app.booking);
  const { reset } = useFormContext<BookingFormData>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const deleteReservation = async (id: number) => {
    try {
      await dispatch(bookingActions.deleteReservation(id));
      await dispatch(reservationsActions.getFullReservationsForDay());
      dispatch(bookingActions.closeAndResetBooking(reset));
    } catch (error) {
      enqueueSnackbar(t('createReservationError'), { variant: MessageVariants.Error });
    } finally {
      setShowButtons(false);
    }
  };

  const isDeleteButton =
    id && (selectedStatus === Statuses.Blocked || selectedStatus === Statuses.WalkIn);

  return isDeleteButton ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' },
        gap: '8px',
        flexWrap: 'wrap',
      }}
    >
      <Button
        color="error"
        onClick={() => setShowButtons(true)}
        sx={{ maxWidth: '64px' }}
        disabled={isReadOnly}
      >
        <Typography sx={{ wordBreak: 'break-word' }}>{t('deleteReservation')}</Typography>
      </Button>
      {showButtons && (
        <>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteReservation(id)}
            sx={{ maxWidth: '64px' }}
          >
            <Typography sx={{ wordBreak: 'break-word' }}>{t('yes')}</Typography>
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => setShowButtons(false)}
            sx={{ maxWidth: '64px' }}
          >
            <Typography sx={{ wordBreak: 'break-word' }}>{t('no')}</Typography>
          </Button>
        </>
      )}
    </Box>
  ) : null;
};
