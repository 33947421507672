import { ReservationTagTypes } from 'types/reservation';
import { ReactComponent as WheelchairIcon } from 'images/icons/Tags/ic-wheelchair.svg';
import { ReactComponent as WithKidsIcon } from 'images/icons/Tags/ic-with-kids.svg';
import { ReactComponent as AnniversaryIcon } from 'images/icons/Tags/ic-anniversary.svg';
import { ReactComponent as HighChairIcon } from 'images/icons/Tags/ic-highchair.svg';
import { ReactComponent as GiftVoucherIcon } from 'images/icons/Tags/ic-gift-voucher.svg';
import { ReactComponent as WithPetIcon } from 'images/icons/Tags/ic-pets.svg';

export const reservationTagIcons = [
  {
    name: ReservationTagTypes.Anniversary,
    icon: <AnniversaryIcon />,
  },
  {
    name: ReservationTagTypes.WheelchairAccess,
    icon: <WheelchairIcon />,
  },
  {
    name: ReservationTagTypes.WithKids,
    icon: <WithKidsIcon />,
  },
  {
    name: ReservationTagTypes.HighChair,
    icon: <HighChairIcon />,
  },
  {
    name: ReservationTagTypes.GiftVoucher,
    icon: <GiftVoucherIcon />,
  },
  {
    name: ReservationTagTypes.WithPet,
    icon: <WithPetIcon />,
  },
];
