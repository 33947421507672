import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { CSSProperties } from 'react';

export enum DialogVariant {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type StyledDialogProps = {
  variant?: DialogVariant;
  paperSx?: CSSProperties;
};

export const StyledDialog = styled(Dialog, { shouldForwardProp: (prop) => prop !== 'paperSx' })(
  ({ variant, paperSx }: StyledDialogProps) => ({
    '& .MuiDialog-container': {
      background: 'rgba(5, 0, 153, 0.2)',
      justifyContent: 'center',
    },
    '& .MuiPaper-root': {
      margin: 0,
      maxWidth: '586px',
      width: '100%',
      boxShadow: 'none',
      ...(variant === DialogVariant.Large && {
        maxWidth: '1024px',
      }),
      ...paperSx,
    },
  }),
);
