import { Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';
import { useTranslation } from 'react-i18next';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { CommentDate } from 'app/components/NewBookingModal/_components/ReservationComments/_components/CommentDate';
import { IComment } from 'types/app/reservations';
import { Heading } from 'app/components/Heading';
import { useStatus } from 'hooks/useStatus';

export const ReservationComments = () => {
  const { isReadOnly } = useStatus();

  const { t } = useTranslation();

  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: bookingFormFieldNames.comments,
  });

  return (
    <Box>
      {fields.map((field, index) => {
        const comment = field as unknown as IComment;

        return (
          <>
            {index === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  marginBottom: '12px',
                }}
              >
                <Heading sx={{ marginBottom: 0 }}>{t('comment')}</Heading>
                {comment.changed && <CommentDate changed={comment.changed} />}
              </Box>
            )}
            <Box key={field.id} sx={{ marginBottom: '8px' }}>
              <MuiTextarea
                name={`comments[${index}].text`}
                rows={3}
                isExpanded={true}
                disabled={isReadOnly}
              />
            </Box>
          </>
        );
      })}
    </Box>
  );
};
