import { BookingStatusGroups, Statuses } from 'types/reservation';
import { Box } from '@mui/material';
import { BookingGroups } from 'app/components/NewBookingModal/_components/BookingGroups';
import { BookingStatuses } from 'types/reservation';
import { DeleteReservationButton } from 'app/components/NewBookingModal/_components/DeleteReservationButton';
import { useTheme } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';
// import { BookingStatusButtons } from 'app/components/NewBookingModal/_components/BookingStatusButtons';

interface OwnProps {
  setBookingStatus: (status: Statuses) => void;
  selectedStatus: Statuses;
  isNewReservation: boolean;
  setSelectedStatusGroup: (group_statuses: BookingStatuses[]) => void;
  selectedGroupName: BookingStatusGroups;
  setSelectedGroupName: (groupName: BookingStatusGroups) => void;
}

export const BookingLeftBar = ({
  setBookingStatus,
  selectedStatus,
  isNewReservation,
  setSelectedStatusGroup,
  selectedGroupName,
  setSelectedGroupName,
}: OwnProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  if (isMobile) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: { xs: 'row', md: 'column', gap: theme.customSpacing.bookingModal },
      }}
    >
      {/*<BookingStatusButtons*/}
      {/*  setBookingStatus={setBookingStatus}*/}
      {/*  selectedStatus={selectedStatus}*/}
      {/*  isNewReservation={isNewReservation}*/}
      {/*/>*/}
      <BookingGroups
        setBookingStatus={setBookingStatus}
        isNewReservation={isNewReservation}
        setSelectedStatusGroup={setSelectedStatusGroup}
        selectedGroupName={selectedGroupName}
        setSelectedGroupName={setSelectedGroupName}
      />
      <DeleteReservationButton selectedStatus={selectedStatus} />
    </Box>
  );
};
