import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { SxProps } from '@mui/material';

interface OwnProps {
  name: string;
  firstColor?: string;
  secondColor?: string;
  placeholder?: string;
  sx?: SxProps;
  disabled?: boolean;
}

export const ImportantMessageInput = ({
  name,
  firstColor,
  secondColor,
  placeholder,
  sx,
  disabled,
}: OwnProps) => {
  return (
    <ControlledInput
      name={name}
      maxLengthColor={firstColor}
      multiline
      placeholder={placeholder}
      disabled={disabled}
      inputBaseSx={{
        color: firstColor,
      }}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: secondColor,
          padding: '0px 4px',
        },
        '& fieldset': {
          borderColor: firstColor,
        },
        ...sx,
      }}
    />
  );
};
