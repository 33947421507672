import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as TimeIcon } from 'images/icons/ic-solid-time.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { timestamp } from 'utils/date-time/timestamp';
import moment from 'moment';
import { getAmountDivider } from 'utils/number/getAmountDivider';
import OriginIcon from 'app/components/OriginIcon';

const iconWrapperStyles = {
  margin: '2px 4px 0 0',
};

export const ReservationNote = () => {
  const {
    created_at,
    updated_at,
    original_start_time,
    special_offer,
    stripe_payment_data,
    origin,
  } = useAppSelector((state) => state.app.booking);

  const lateCancellation = special_offer?.late_cancellation;

  const theme = useTheme();
  const { t } = useTranslation();

  const formattedPaymentDetails = (): string => {
    if (!stripe_payment_data || !stripe_payment_data.paidAmount) return '';

    const amount = getAmountDivider(stripe_payment_data?.paidAmount);
    const currency = lateCancellation?.currency || '';

    return `${t('lateCancellation')} ${amount} ${currency}`;
  };

  const noteItems = [
    {
      icon: (
        <Box sx={{ margin: '0 2px 0 0' }}>
          <OriginIcon origin={origin} />
        </Box>
      ),
      name: t('reservationNoteReserved'),
      date: created_at,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: t('reservationNoteLastUpdate'),
      date: updated_at,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: t('reservationVisitNumber'),
      date: original_start_time,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: formattedPaymentDetails(),
      date: stripe_payment_data && stripe_payment_data?.paidAt,
      isFormatted: true,
    },
  ];

  const noteItemsToRender = noteItems
    .filter((item) => item.date)
    .map(({ icon, name, date, isFormatted }) => (
      <Grid item xs={12} md={6} key={name}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', color: 'navyBlue' }}>{icon}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: 'navyBlue',
                wordBreak: 'break-word',
              }}
            >
              {name}
            </Typography>
            {date && (
              <Typography sx={{ fontSize: '14px', color: 'comet', wordBreak: 'break-word' }}>
                {isFormatted
                  ? date
                  : moment(timestamp(date as number).toMilliseconds).format('YYYY-MM-DD - HH:mm')}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    ));

  if (noteItemsToRender.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'barleyWhite',
        borderRadius: theme.borderRadius.small,
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        marginBottom: '16px',
      }}
    >
      <Grid container spacing={2}>
        {noteItemsToRender}
      </Grid>
    </Box>
  );
};
