import { MutableRefObject, useMemo } from 'react';
import { BookingModalSection } from 'app/components/NewBookingModal/_components/BookingModalSection';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useState } from 'react';
import { NumbersOfGuests } from 'app/components/NewBookingModal/_components/NumbersOfGuests';
import { DateAndTime } from 'app/components/NewBookingModal/_components/DateAndTime';
import { CustomNarrowButton } from 'app/components/Buttons/CustomNarrowButton';
import { AvailableTables } from 'app/components/NewBookingModal/_components/AvailableTables';
import { ReservationTags } from 'app/components/NewBookingModal/_components/ReservationTags';
import { ReservationComments } from 'app/components/NewBookingModal/_components/ReservationComments';
import { ImportantMessageInput } from 'app/components/NewBookingModal/_components/ImportantMessageInput';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { CustomFileUpload } from 'app/components/CustomFileUpload';
import {
  AvailableTablesViews,
  bookingModalConfig,
  BookingModalLayouts,
  BookingModalViews,
} from 'app/components/NewBookingModal/_config';
import { Statuses } from 'types/reservation';
import { ReservationNote } from 'app/components/NewBookingModal/_components/ReservationNote';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { useAppSelector } from 'redux/hooks';
import { DateControl, DateControlVariants } from 'app/components/DateControl';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { checkIfReservationIsNew } from 'utils/reservation/checkIfReservationIsNew';
import { StyledTextarea } from 'app/components/FormElements/StyledTextarea';
import { ArrowUpButton } from 'app/components/Buttons/ArrowUpButton';
import { calculatePaymentAmount } from 'utils/reservation/calculatePaymentAmount';
import { StripePaymentStatuses } from 'enums/stripePaymentStatuses';
import { useStatus } from 'hooks/useStatus';

interface OwnProps {
  selectedStatus: Statuses;
  handleModalView: (view: BookingModalViews) => void;
  guestsRef: MutableRefObject<any>;
  tablesRef: MutableRefObject<any>;
}

export const ReservationSection = ({
  selectedStatus,
  handleModalView,
  guestsRef,
  tablesRef,
}: OwnProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { isReadOnly } = useStatus();

  const [isNoteShowed, setIsNoteShowed] = useState(false);

  const booking = useAppSelector((state) => state.app.booking);
  const bookingId = booking?.id;
  const guestComments = booking.guest_comments;
  const payments = booking.payments;
  const paidAmount = payments && calculatePaymentAmount(payments[0]);
  const isCardSaved = booking?.stripe_payment_data?.status === StripePaymentStatuses.Succeeded;

  const { guests, created_at, id, important_message } = booking;

  const [isImportant, setIsImportant] = useState(!!important_message);

  const isNewReservation = checkIfReservationIsNew(bookingId);

  const { showReservationTags, showExperiences, showFilesAndExtraInformation, showComments } =
    bookingModalConfig(selectedStatus);

  const startTime = watch(bookingFormFieldNames.start_time);

  //TODO timestamp method
  const dateOnlyTimestamp = moment
    .utc(startTime * 1000)
    .startOf('day')
    .unix();

  const specialOffers = useAppSelector((state) => state.app.special_offers);
  const weekDayNumber = moment.unix(startTime).day();

  const filteredSpecialOffers = useMemo(() => {
    return specialOffers
      .filter(
        ({ date, valid_week }) =>
          dateOnlyTimestamp >= date.start &&
          dateOnlyTimestamp <= date.end &&
          valid_week.some(({ day_of_week, enabled }) => day_of_week === weekDayNumber && enabled),
      )
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));
  }, [specialOffers, dateOnlyTimestamp, weekDayNumber]);

  const statuses = [
    ...(paidAmount
      ? [
          {
            name: t('paidAmount', { paidAmount }),
            status: 'paid',
          },
        ]
      : []),
    ...(isCardSaved
      ? [
          {
            name: 'Card saved',
            status: 'card_saved',
          },
        ]
      : []),
  ];

  const formatAdditionalText = (
    guests: number,
    createdAt: number | undefined,
    id: number | undefined,
  ) => {
    const guestText = guests > 0 ? `${guests} ${guests === 1 ? t('person') : t('persons')}` : '';
    const dateText = createdAt ? `${moment.unix(createdAt).format('MMMM D')}` : '';
    const timeText = createdAt ? `${moment.unix(createdAt).format('HH:mm')}` : '';
    const idText = id ? `#${id}` : '';

    return [guestText, dateText, timeText, idText]
      .filter((text) => text)
      .join(' · ')
      .trim();
  };

  const toggleNote = () => {
    setIsNoteShowed((prevState) => !prevState);
  };

  return (
    <BookingModalSection
      name={t('bookingModalReservationTitle')}
      statuses={statuses}
      additionalText={formatAdditionalText(guests, created_at, id)}
      additionalElements={
        <>
          {!isNewReservation ? <ArrowUpButton onClick={toggleNote} open={isNoteShowed} /> : null}{' '}
          <CustomNarrowButton
            variant={isImportant ? 'contained' : 'text'}
            color="error"
            onClick={() => setIsImportant((prevState) => !prevState)}
            name={t('important')}
            textVariantBgColor="chablis"
            sx={{ marginLeft: '8px' }}
          />
        </>
      }
      sx={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
        minWidth: { xs: 'unset', sm: '535px' },
      }}
    >
      {isImportant && (
        <ImportantMessageInput
          name={bookingFormFieldNames.message}
          firstColor="accent.red"
          secondColor="fairPink"
          sx={{ marginBottom: '16px' }}
          disabled={isReadOnly}
        />
      )}
      {!isNewReservation && isNoteShowed && <ReservationNote />}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <DateControl variant={DateControlVariants.Modal} />
        <NumbersOfGuests guestsRef={guestsRef} />
        <DateAndTime />
        <AvailableTables
          variant={AvailableTablesViews.Simplified}
          handleModalView={handleModalView}
          tablesLayout={BookingModalLayouts.Tables}
          tablesRef={tablesRef}
        />
        {guestComments?.map((guestComment) => (
          <StyledTextarea
            label={t('guestRequestsBookedOnline')}
            value={guestComment?.text}
            changed={guestComment?.changed}
            disabled
          />
        ))}
        {showComments && <ReservationComments />}
        {showReservationTags && <ReservationTags />}
        {showExperiences && (
          <MuiSelect
            disabled={isReadOnly}
            name={bookingFormFieldNames.special_offers}
            options={[
              {
                label: t('noSpecialOffer'),
                value: null,
              } as any,
              ...filteredSpecialOffers,
            ]}
            label={t('reservationExperiencesLabel')}
            placeholder={t('pleaseSelect')}
          />
        )}
        {showFilesAndExtraInformation && (
          <CustomFileUpload multiple={true} name={bookingFormFieldNames.files} accept=".pdf" />
        )}
      </Box>
    </BookingModalSection>
  );
};
