import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'types';
import notesActions from 'redux/actions/notes';
import moment from 'moment';
import { Note, NotesColors } from 'types/notes';
import { SideModal } from 'app/components/Modals/SideModal';

interface StateProps {
  deleteNoteLoading: boolean;
}

interface DispatchProps {
  deleteNote: (id: string, deleteAll: boolean, callback?: () => void) => void;
}

interface DefaultProps {
  note: Note;
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
}

type Props = DefaultProps & StateProps & DispatchProps;

const NotesDeleteModal: React.FC<Props> = ({
  isOpen,
  onClose,
  note,
  deleteNote,
  deleteNoteLoading,
  onReset,
}) => {
  const { t } = useTranslation();

  const isSingleNote = moment(note.start_date).isSame(moment(note.end_date), 'day');

  const [totalDelete, setTotalDelete] = useState(isSingleNote);

  const handleTotalChange = (_: SyntheticEvent, checked: boolean) => {
    setTotalDelete(checked);
  };

  const resetAndClose = () => {
    onReset();
    onClose();
  };

  const handleDeleteNote = () => {
    deleteNote(note.id, totalDelete, resetAndClose);
  };

  const handleClose = () => {
    if (deleteNoteLoading) return;
    onClose();
  };

  return (
    <SideModal
      open={isOpen}
      onClose={handleClose}
      title={t('Confirm Delete Note')}
      withSaveButton={false}
      isLoading={deleteNoteLoading}
      buttons={
        <>
          <Button
            sx={{ height: '40px', lineHeight: 1 }}
            color="secondary"
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            sx={{ height: '40px', lineHeight: 1 }}
            variant="contained"
            color="error"
            onClick={handleDeleteNote}
            autoFocus
            disabled={deleteNoteLoading}
          >
            {t('Delete')}
          </Button>
        </>
      }
    >
      <Typography sx={{ color: 'comet' }}>
        {t('Do you really want to delete this note? This process cannot be undone.')}
      </Typography>
      {!isSingleNote && (
        <FormControlLabel
          control={<Checkbox style={{ color: NotesColors['danger'] }} />}
          label="Delete this note for whole period"
          value={totalDelete}
          onChange={handleTotalChange}
        />
      )}
      {deleteNoteLoading && (
        <div className="notes-card__loader">
          <CircularProgress />
        </div>
      )}
    </SideModal>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    deleteNoteLoading: state.notes.deleteNoteLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    deleteNote: bindActionCreators(notesActions.deleteNote, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDeleteModal);
