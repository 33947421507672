import { MouseEvent } from 'react';
import { AvailableTables } from 'app/components/NewBookingModal/_components/AvailableTables';
import {
  AvailableTablesViews,
  BookingModalLayouts,
  BookingModalViews,
} from 'app/components/NewBookingModal/_config';
import { CustomNarrowButton } from 'app/components/Buttons/CustomNarrowButton';
import { useTranslation } from 'react-i18next';
import { BookingModalSection } from 'app/components/NewBookingModal/_components/BookingModalSection';
import { Box, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import { bookingModalSectionHeight } from 'styles/constants/spacing';
import { ReactComponent as RefreshIcon } from 'images/icons/ic-repeat.svg';

interface OwnProps {
  handleModalView: (view: BookingModalViews) => void;
  tablesLayout: BookingModalLayouts;
  handleTablesLayout: (layout: BookingModalLayouts) => void;
}

const toggleButtonStyles = {
  height: '100%',
  color: 'comet',
  fontSize: '12px',
  fontWeight: '500',
  textTransform: 'capitalize',
  borderColor: 'borderColor',
  padding: '3px 12px',
  '&.Mui-selected': {
    backgroundColor: 'borderColor',
  },
};

const flexWrapStyles = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
};

export const AvailableTablesSection = ({
  handleModalView,
  tablesLayout,
  handleTablesLayout,
}: OwnProps) => {
  const { t } = useTranslation();

  const handleLayoutChange = (event: MouseEvent<HTMLElement>, newLayout: BookingModalLayouts) => {
    if (newLayout !== null) {
      handleTablesLayout(newLayout);
    }
  };

  return (
    <BookingModalSection
      name={t('bookingModalSelectTablesTitle')}
      sx={{
        minHeight: `${bookingModalSectionHeight}px`,
      }}
      headerWrapperSx={{
        flex: 1,
        width: '100%',
        marginLeft: { xs: '0', md: '16px' },
      }}
      titleAndHeaderWrapperSx={{
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' },
      }}
      header={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...flexWrapStyles,
              gap: '8px 24px',
            }}
          >
            <ToggleButtonGroup
              value={tablesLayout}
              exclusive
              onChange={handleLayoutChange}
              sx={{ minHeight: '29px' }}
            >
              <ToggleButton value={BookingModalLayouts.Tables} sx={toggleButtonStyles}>
                {t('modalTablesLayout')}
              </ToggleButton>
              <ToggleButton value={BookingModalLayouts.FloorPLan} sx={toggleButtonStyles}>
                {t('modalFloorPlanLayout')}
              </ToggleButton>
            </ToggleButtonGroup>
            <Box
              sx={{
                ...flexWrapStyles,
                gap: '8px',
              }}
            >
              <CustomNarrowButton
                name={t('aiTableSuggestButton')}
                color="secondary"
                onClick={() => {}}
                variant="outlined"
                disabled
              />
              <Button
                variant="outlined"
                disabled
                sx={{ padding: 0, minWidth: 0, width: '29px', height: '29px' }}
              >
                <RefreshIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      }
    >
      <AvailableTables
        variant={AvailableTablesViews.Full}
        handleModalView={handleModalView}
        tablesLayout={tablesLayout}
      />
    </BookingModalSection>
  );
};
