import { ReactNode, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { BookingLeftBar } from 'app/components/NewBookingModal/_components/BookingLeftBar';
import { BookingBody } from 'app/components/NewBookingModal/_components/BookingBody';
import { BookingTopBar } from 'app/components/NewBookingModal/_components/BookingTopBar';
import { BookingBodyWrapper } from 'app/components/NewBookingModal/_components/BookingBodyWrapper';
import {
  bookingStatusGroups,
  BookingStatusGroups,
  mapNewToOldStatuses,
  Statuses,
} from 'types/reservation';
import { findKey } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';
import { BookingModalViews } from 'app/components/NewBookingModal/_config';

interface OwnProps {
  children: ReactNode;
  setBookingStatus: (status: Statuses) => void;
  selectedStatus: Statuses;
  isNewReservation: boolean;
  onClose: () => void;
  handleModalView: (view: BookingModalViews) => void;
  currentView: BookingModalViews;
}

export const BookingContentWrapper = ({
  children,
  setBookingStatus,
  selectedStatus,
  isNewReservation,
  onClose,
  handleModalView,
  currentView,
}: OwnProps) => {
  const theme = useTheme();
  const { isMax900 } = useScreenSize();

  const findGroupNameForStatus = (status: Statuses): BookingStatusGroups => {
    const groupName = findKey(bookingStatusGroups, (statuses) =>
      statuses.some((bookingStatus) => mapNewToOldStatuses[bookingStatus] === status),
    );
    return groupName ? (groupName as BookingStatusGroups) : BookingStatusGroups.Reservation;
  };

  const initialGroupName = findGroupNameForStatus(selectedStatus);
  const initialGroup = bookingStatusGroups[initialGroupName];

  const [selectedStatusGroup, setSelectedStatusGroup] = useState(initialGroup);
  const [selectedGroupName, setSelectedGroupName] = useState(initialGroupName);

  useEffect(() => {
    const newGroupName = findGroupNameForStatus(selectedStatus);
    setSelectedGroupName(newGroupName);
    setSelectedStatusGroup(bookingStatusGroups[newGroupName]);
  }, [selectedStatus]);

  return (
    <BookingBodyWrapper>
      <BookingLeftBar
        setBookingStatus={setBookingStatus}
        selectedStatus={selectedStatus}
        isNewReservation={isNewReservation}
        setSelectedStatusGroup={(group) => setSelectedStatusGroup(group)}
        selectedGroupName={selectedGroupName}
        setSelectedGroupName={(groupName) => setSelectedGroupName(groupName)}
      />
      <BookingBody
        handleModalView={handleModalView}
        currentView={currentView}
        selectedStatus={selectedStatus}
      >
        <BookingTopBar
          setBookingStatus={setBookingStatus}
          selectedStatus={selectedStatus}
          isNewReservation={isNewReservation}
          setSelectedStatusGroup={(group) => setSelectedStatusGroup(group)}
          onClose={onClose}
          selectedStatusGroup={selectedStatusGroup}
          selectedGroupName={selectedGroupName}
          setSelectedGroupName={(groupName) => setSelectedGroupName(groupName)}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            gap: theme.customSpacing.bookingModal,
            flexDirection: isMax900 ? 'column' : 'row',
          }}
        >
          {children}
        </Box>
      </BookingBody>
    </BookingBodyWrapper>
  );
};
