import { ReactElement } from 'react';
import { bookingFormFieldNames } from '../../../_config';
import { ReactComponent as AllergiesIcon } from 'images/icons/ic-leaf.svg';
import { ReactComponent as FamilyIcon } from 'images/icons/ic-user-plus.svg';
import { ReactComponent as EatIcon } from 'images/icons/ic-eat.svg';

export const additionalInfo: { icon: ReactElement; name: any; label: string }[] = [
  // {
  //   icon: <ShieldIcon />,
  //   name: bookingFormFieldNames.client.additional_info.birthday,
  //   label: 'guestBirthDay',
  // },
  {
    icon: <FamilyIcon />,
    name: bookingFormFieldNames.client.additional_info.family_member,
    label: 'guestFamilyMember',
  },
  {
    icon: <EatIcon />,

    name: bookingFormFieldNames.client.additional_info.favourites,
    label: 'guestFavourites',
  },
  {
    icon: <AllergiesIcon />,
    name: bookingFormFieldNames.client.additional_info.description,
    label: 'guestGeneralDescription',
  },
];
