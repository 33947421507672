import { Box, Typography } from '@mui/material';
import TableinLogo from 'images/icons/ic-tablein.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings.svg';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar.svg';
import { ReactComponent as ReportsIcon } from 'images/icons/ic-reports.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list.svg';
import { ReactComponent as FloorPlanIcon } from 'images/icons/ic-floor-plan.svg';
import { ReactComponent as DatabaseIcon } from 'images/icons/ic-guestbook.svg';
import { ReactComponent as HomeIcon } from 'images/icons/ic-home-icon.svg';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SidebarNavLink } from 'app/components/Navigation/_components/SidebarNavLink';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import { useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import useRouteMatcher from 'app/router/hooks/useRouteMatcher';
import paths from 'app/router/paths';

export const SidebarNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const show_sidebars = useAppSelector((state) => state.app.preferences?.show_sidebars);
  const { routeIs } = useRouteMatcher();

  const sidebarNavigationLinks = (t: TFunction) => [
    {
      to: paths.calendar.home,
      icon: <HomeIcon />,
      name: t('navigationHome'),
      hasAccess: true,
    },
    {
      to: paths.calendar.calendar,
      icon: <CalendarIcon />,
      name: t('navigationCalendar'),
      hasAccess: true,
    },
    {
      to: paths.calendar.reservationList,
      icon: <ListIcon />,
      name: t('navigationList'),
      hasAccess: true,
    },
    {
      to: paths.calendar.floorPlan,
      icon: <FloorPlanIcon />,
      name: t('navigationFloorPlan'),
      hasAccess: false,
    },
    {
      to: oldAppUrlGenerate(paths.calendar.reports, false),
      icon: <ReportsIcon />,
      name: t('navigationReports'),
      // hasAccess: accessDashboard,
      hasAccess: false,
    },
    {
      to: paths.calendar.database,
      icon: <DatabaseIcon />,
      name: t('navigationDatabase'),
      hasAccess: false,
    },
    {
      to: paths.settings.accountSetup.restaurantInformation,
      icon: <SettingsIcon />,
      name: t('navigationSettings'),
      hasAccess: true,
    },
  ];

  const isLinkActive = (path: string, currentPath: string) => {
    return currentPath === path || currentPath.includes(`${path}/`);
  };

  const sidebarNavigationLinksToRender = sidebarNavigationLinks(t).map(
    ({ to, name, icon, hasAccess }) => {
      const isActive = isLinkActive(to, pathname);

      const handleBlockRedirect = (e: any) => {
        if (!hasAccess) e.preventDefault();
      };

      return (
        <SidebarNavLink
          onClick={handleBlockRedirect}
          to={to}
          style={{ textDecoration: 'none', color: 'white' }}
          key={name}
          isActive={isActive}
          disabled={!hasAccess}
        >
          {icon}
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '10px',
              fontWeight: 600,
              maxWidth: '60px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className="settings-navigation-link"
          >
            {name}
          </Typography>
        </SidebarNavLink>
      );
    },
  );

  const mainLinks = sidebarNavigationLinksToRender.slice(0, -3);
  const otherLinks = sidebarNavigationLinksToRender.slice(-3);

  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: routeIs(paths.calendar.calendar) ? (show_sidebars ? 'flex' : 'none') : 'flex',
        },
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '80px',
        backgroundColor: 'darkerBlue',
        padding: '12px',
        height: '100%',
        position: 'sticky',
        top: 0,
      }}
      className="no-print"
    >
      <Box
        component="img"
        src={TableinLogo}
        alt={t('tableinLogoAlt')}
        sx={{ width: '52px', height: '13px', marginTop: '12px' }}
        loading="lazy"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          marginTop: '27px',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>{mainLinks}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>{otherLinks}</Box>
      </Box>
    </Box>
  );
};
