import { createRef, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'images/icons/ic-x-close.svg';
import Notifier from '../Notifier';
import './NotificationsProvider.scss';

interface Props {
  children: ReactNode;
}

const NotificationsProvider = ({ children }: Props) => {
  const notistackRef = createRef();

  const onClickDismiss = (key: any) => () => {
    /* @ts-ignore */
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      {/* @ts-ignore */}
      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={1000 * 4}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{
          root: 'snackbar-notification',
          /* @ts-ignore */
          variantSuccess: 'snackbar-notification__content--success',
        }}
        action={(key) => (
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        )}
      >
        {children}
        <Notifier />
      </SnackbarProvider>
    </>
  );
};

export default NotificationsProvider;
